@import '../../colors.scss';
@import '../../global.scss';

.admin-orders {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .row-missing-req .ant-table-cell {
    background-color: #ffcccc !important;
    /* light red background color */
  }

  .start-time-col {
    padding-left: 0px !important;
  }

  a {
    color: $success;
  }

  .open-consult-btn {
    font-size: 11px;
    font-weight: 500;
    height: 28px;
    border: 0;
    margin-left: 15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
  }


  .appointment-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
  }

  .pending-status {
    color: $secondary_text;
    background: $secondary_bg;
    font-size: 13px;
  }

  .collected-status {
    background: $warning_bg;
    color: $warning;
  }

  .complete-status {
    background: $success_bg;
    color: $success;
  }

  .canceled-status,
  .deactivated-status {
    color: $error;
    background: $error_bg;
  }

  .confirmed-status {
    color: $primary_text;
    background: $secondary_bg;
  }
}