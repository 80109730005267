@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {

    .test-catalog {

        .filter-buttons {
            display: flex;
            justify-content: left;
            margin-bottom: 20px;
            margin-left: -10px;
            overflow-x: auto; // Allow horizontal scroll
            white-space: nowrap; // Prevent the buttons from wrapping

            // Add padding for scrollable container
            padding-bottom: 10px;
            -webkit-overflow-scrolling: touch; // Enable smooth scrolling

            .ant-btn {
                background-color: #f0f0f0;
                border-radius: 20px;
                margin: 0 10px;
                padding: 10px 20px;
                border: none;
                color: #333;
                font-weight: 500;
                transition: all 0.3s ease;


                &:hover {
                    background-color: #ddd;
                    transform: scale(1.05);
                }

                &.active {
                    color: $success;
                    background-color: darken($success_bg, 10%);
                }


            }
        }

        .recommended-note {
            background-color: white;
            border-left: 4px solid $success;
            padding: 15px;
            font-size: $h6;
            line-height: $h6_line_height;
            color: #333;
            margin-bottom: 20px;
            border-radius: 4px;
        }

        .recommended-note a {
            text-decoration: underline;
            transition: color 0.3s ease;
            color: gray;

            &:hover {
                text-decoration: underline;
            }
        }


        .test-catalog-item {
            padding: 20px;
            border: 1px solid $border;
            border-radius: 5px;
            background-color: #fff;
            margin-bottom: 20px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s, transform 0.3s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly increase shadow
                transform: translateY(-5px); // Move panel up slightly
            }

            &.most-popular {
                border: 2px $error solid;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                position: relative;
            }

            &.is-trending {
                border: 2px darken(#B8D8E8, 10%) solid;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                position: relative;
            }

            .most-popular-tag {
                background-color: $error;
                color: #fff;
                border: none;
                position: absolute;
                top: -10px;
                right: -15px;
                border-radius: 5px;
                padding: 5px 10px;
                font-weight: bold;
                z-index: 1;
            }

            .is-trending-tag {
                background-color: darken(#B8D8E8, 10%);
                color: #fff;
                border: none;
                position: absolute;
                top: -10px;
                right: -15px;
                border-radius: 5px;
                padding: 5px 10px;
                font-weight: bold;
                z-index: 1;
            }
        }

        .test-title {
            font-weight: 600;
            font-size: $h3;
            line-height: $h3_line_height;
            padding: 15px 0px 0px;
            color: black;
            // font-family: 'Cooper LT BT';
            // letter-spacing: 1px;
        }

        .test-description {
            height: 3em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: $h5;
            line-height: $h5_line_height;
        }

        .test-labels {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: -10px;
            height: calc(1.1*$h1);


            .test-label-svg {
                width: $h4;
                line-height: $h4_line_height;
                color: #bbbbbb;
            }



            .test-label-tags {
                display: flex;
                align-items: center;
                justify-content: flex-end;

            }

            .at-home {
                color: darken(#C3B0C6, 15);
                background: lighten(#C3B0C6, 23);
                border: 0;
            }

            .self-collect {
                color: darken(#B9CCD1, 12);
                background: lighten(#B9CCD1, 19);
                border: 0;
            }

            .at-office {
                color: darken(#EAC590, 10);
                background: lighten(#EAC590, 22);
                border: 0;
            }

            .member-only {
                color: darken(#B0B0B0, 20);
                /* A light gray */
                background: lighten(#B0B0B0, 25);
                border: 0;
            }
        }

        .learn-more-button-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 15px;

            .learn-more-button {
                // text-decoration: underline;
                font-weight: 500;
            }
        }

        .test-price {
            font-weight: 500;
            font-size: $h5;

            .regular-price {
                text-decoration: line-through;
                margin-right: 5px;
                color: darkgray;
            }
        }

        .member-price {
            color: #ff3b30;
        }

        .member-tag {
            color: #ff3b30;
            border: 1px solid #ff3b30;
            background-color: transparent;
            font-size: calc($h5*0.6);
            // text-transform: uppercase;
            font-weight: 600;

        }

        .learn-more-button {
            text-decoration: none;

        }



    }

    .view-more-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .view-btn {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: $h4;
    }


    .new-tag {
        background-color: $success;
        color: #fff;
        border: none;
        font-size: calc($h5*0.6);
        font-weight: 600;
    }


}

@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );



}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}