@import '../../global.scss';
@import '../../colors.scss';

$select_height: 42px;
$button_height: 42px;
$gap_size: 8px; // Slightly increase gap for more spacing

.results {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; // Ensure items are vertically aligned
  }

  .actions-container {
    display: flex;
    align-items: center;
    gap: $gap_size;

    .view-toggle-switch {
      min-width: 100px;

      &.ant-switch {
        background-color: $blue;

        .ant-switch-inner {
          height: unset;

          .ant-switch-inner-checked {
            line-height: 35px;
          }

          .ant-switch-inner-unchecked {
            line-height: 10px;
          }
        }

        .ant-switch-handle {
          top: 8px;
          inset-inline-start: 7px;
        }

        &.ant-switch-checked .ant-switch-handle {
          inset-inline-start: calc(100% - 27px);
        }
      }
    }
  }

  .download-btn,
  .send-btn,
  .toggle-view-btn,
  .filter-btn {
    height: $button_height;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px; // Ensure padding for icons inside buttons
    border-radius: 4px; // Optional: add button radius for a modern look
    width: $button_height;
  }

  .select-filter {
    // width: 155px; // Adjusted to fit longer text options
    height: $select_height;
  }

  .ant-select-selector {
    height: $select_height !important;
    display: flex;
    align-items: center;
  }

  .ant-btn {
    line-height: $select_height; // Ensure button text aligns with the button height
  }

  .downloading-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-view-btn {
    height: $button_height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-toggle-switch {
    min-width: 120px;
    margin-right: 8px;

    &.ant-switch-checked,
    &.ant-switch {
      background-color: #1890ff; // Ant Design's primary blue color
    }
  }
}