@import "../../colors.scss";


.payment-form {
    width: 100%;

    .ant-tabs {
        width: 100%;

        .ant-tabs-nav {
            border-bottom: none;

            &::before {
                border: none;
            }

            .ant-tabs-nav-list {
                width: 100%;
                display: flex;
                gap: 12px;

                .ant-tabs-tab {
                    flex: 1;
                    margin: 0;
                    padding: 12px;
                    background: #f5f5f5;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    justify-content: center;

                    &.ant-tabs-tab-active {
                        background: #ffffff;
                        border-color: $success;

                        .ant-tabs-tab-btn {
                            color: $success;
                        }

                        &:hover {
                            border-color: $success;
                        }
                    }
                }

                .ant-tabs-ink-bar {
                    display: none;
                }
            }
        }

        .klarna-container,
        .card-container,
        .affirm-container {
            padding-top: 20px;
        }
    }


    .apple-pay-container {
        padding: 15px 0px 0px;
    }

    .separator {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 20px 0;

        span {
            padding: 0 10px;
            color: #666;
            font-size: 14px;
        }

        &::before,
        &::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #ddd;
        }
    }

    .processing-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        p {
            margin-top: 1rem;
            color: #666;
        }
    }

    .logo {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;

        svg {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            display: block;
            z-index: 1;
        }

        &.card {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .confirm-btn,
    .skip-btn {
        width: 100%;
        margin-top: 1rem;
        font-weight: 600;
        padding: 0 15px;
        height: 48px;
        font-size: 16px;
    }
}