@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .desktop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    position: relative;
    height: 70px;
    z-index: 3;
    width: 100%;
    border-bottom: 1px solid $border;
    background: white;

    .ant-col {
      height: 100%;
      align-items: center;
      display: flex;
    }

    a {
      text-decoration: none !important;
    }

    .logo-container {
      cursor: pointer;
    }

    .logo {
      width: 22px;
      vertical-align: middle;
      float: left;
      margin-right: 10px;
      margin-top: 3px;
    }

    .logo-name {
      font-size: $h4;
      line-height: $h4_line_height;
      display: inline-block;
      font-weight: 600;
    }

    .header-item-list {
      display: flex;
      justify-content: space-between;
      column-gap: 25px;
      font-weight: 500;
      align-items: center;
    }

    .header-item a {
      color: $secondary_text;
      font-size: calc(0.9*$h5);
      line-height: calc(0.9*$h5_line_height);
    }

    .patient-search-bar {
      width: 500px;
      height: 100%;

      &.ant-select-focused .ant-select-selector {
        border-left: 1px solid $border !important;
        border-right: 1px solid $border !important;
      }

      .ant-select-selector {
        border-bottom: 0px !important;
        border-top: 0px !important;
      }

      .ant-select-selector,
      .ant-select-selection-search,
      .ant-select-selection-search-input {
        height: 100% !important;
        border-radius: 0 !important;
      }
    }
  }
}

.ant-select-item-option-active {
  background: linear-gradient(rgba(82, 196, 26, 0.04), rgba(82, 196, 26, 0.08)) !important;

  &:active {
    background: linear-gradient(rgba(82, 196, 26, 0.08), rgba(82, 196, 26, 0.12)) !important;
    box-shadow: inset 0 1px 2px rgba(82, 196, 26, 0.1) !important;
  }

  &:hover {
    .patient-name {
      color: $success !important; // Ant Design's green color
    }
  }
}

.patient-option {
  padding: 4px 3px;
  
  .patient-name {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .patient-email {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 1.2;
  }
}

// Style the selected state
.ant-select-item-option-selected .patient-option {
  .patient-name {
    color: #1890ff;
  }
  .patient-email {
    color: rgba(24, 144, 255, 0.65);
  }
}

.quick-actions {
  padding: 8px 0;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;

  &-header {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 500;
    padding: 4px 16px;
    margin-bottom: 4px;
  }

  &-list {
    display: flex;
    flex-direction: column;
  }

  .quick-actions-list {
    padding-bottom: 4px;
  }

  .quick-action-btn {
    text-align: left;
    height: 36px;
    padding: 0 12px;
    width: calc(100% - 8px);
    margin-left: 4px;
    transition: all 0.2s;
    display: block;
    
    &:hover {
      background: #f5f5f5;
    }

    .anticon {
      width: 16px;
      margin-right: 12px;
      color: rgba(0, 0, 0, 0.45);
    }

    .path-separator {
      color: rgba(0, 0, 0, 0.25);
      margin: 0 8px;
    }

    .path-detail {
      color: rgba(0, 0, 0, 0.45);
      font-weight: normal;
      position: relative;
    }

    span:not(.anticon):not(.path-separator):not(.path-detail) {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }

    .quick-action-count {
      margin-left: auto;
      width: 20px;
      height: 20px;
      margin-top: -21px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px !important;
      font-weight: 600;
      border-radius: 10px;
      background: rgba(12, 163, 127, 0.15);
      color: $success !important;
      line-height: 1;
      transition: all 0.2s ease;
      font-feature-settings: "tnum";
      
      // Subtle glow and shadow for depth
      box-shadow: inset 0 0 0 1px rgba(12, 163, 127, 0.3),
                  0 1px 2px rgba(12, 163, 127, 0.1);
    }
  }

  .search-results-footer {
    padding: 8px 16px;
    border-top: 1px solid #f0f0f0;
    margin-bottom: -10px;
    background: #fafafa;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    
    .search-results-info {
      display: flex;
      gap: 4px;
      align-items: center;
      
      .search-results-label {
        color: rgba(0, 0, 0, 0.45);
      }
      
      .search-results-name {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
      }
      
      .search-results-email {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}
