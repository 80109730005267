@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h4,
  $h5,
) {
  .pg-footer {
    position: relative;
    padding: $vertical_padding 0 calc($vertical_padding*1.5) 0;
    overflow: hidden;

    .pg-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

    .pg-quote {
      font-size: $h3;
      color: white;
      z-index: 1;
      position: relative;
      margin-bottom: calc($vertical_padding/2);
    }

    .pg-row {
      align-items: center;
    }

    .pg-img {
      width: $vertical_padding;
      height: $vertical_padding;
      border-radius: 100%;
      margin-right: 10px;
    }

    .pg-name {
      font-size: $h4;
      font-weight: 600;
      margin-bottom: 0;
      color: white;
      margin-top: 0;
    }

    .pg-blog {
      font-size: $h4;
      font-style: italic;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 5px;
    }

    .footer {
      z-index: 1;
      position: absolute;
      width: 100%;
      bottom: 20px;
      left: 0;
      text-align: center;
    }

    .footer span {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      padding-right: calc($horizontal_padding/2);
      margin-right: calc($horizontal_padding/2);
      margin-top: 10px;
      display: inline-block;
      color: white;

      a {
        color: white;
      }
    }

    .footer span:last-child {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h4: $mobile_h4,
    $h5: $mobile_h5,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h4: $desktop_h4,
    $h5: $desktop_h5,
  );
}