@import '../../colors.scss';
@import '../../global.scss';

.admin-prescriptions-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .prescription-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
  }
  
  a {
    color: $success;
  }

  .pending-prescription-status {
    color: $secondary_text;
    background: $secondary_bg;
  }

  .paused-prescription-status {
    color: $error;
    background: $error_bg;
  }

  .sent-prescription-status {
    color: $success;
    background: $success_bg;
  }

  .has-order-col {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .order-status {
    font-size: 12px;
    padding-top: 7px;
  }

  .check-icon {
    color: $success;
    margin-right: 5px;
  }

  .send-btn {
    font-size: 12px;
    padding: 0 10px;
    font-weight: 600;

    &:hover {
      border: 1px solid lighten($success, 15);
      color: lighten($success, 15);
    }

    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  .tracking-link {
    color: $success;
  }
}

.tracking-list {
  margin-left: 0;
  padding-left: 20px;
  padding-right: 10px;

  li {
    font-size: 12px;
    margin-bottom: 10px;
  }
}