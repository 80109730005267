
@import '../../colors.scss';

$height: 200px;

.fsp-header {
  display: none !important;
}

.fsp-picker--inline,
.fsp-picker-holder,
.fsp-picker,
.fsp-modal {
  min-height: $height !important;
}

.fsp-content--transformer {
  padding: 0 !important;
}

.cropper-container {
  height: 150px !important;
}

.provider-form-photo {
  display: inline-block;
  position: relative;
  cursor: pointer;

  img {
    height: 100px;
    float: left;
    border-radius: 10px;
  }

  .provider-photo-overlay {
    display: none;
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    .anticon {
      color: $error;
      font-size: 22px;
    }

    &:active {
      background: rgba(0,0,0,0.6);
    }
  }

  &:hover .provider-photo-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.provider-form {
  position: relative;
  
  .fsp-picker,
  .fsp-modal,
  .fsp-drop-area,
  .fsp-content,
  .fsp-footer {
    background: $secondary_bg;
  }
  
  .fsp-picker--inline .fsp-picker {
    border-top: 1px solid $border;
    border-left: 1px solid $border;
    border-right: 1px solid $border;
    height: $height;
  }
  
  .fst-sidebar {
    border-bottom: 1px solid $border;
  }
  
  .fsp-modal {
    border: 0;
  }
  
  .fsp-modal__body {
    border-bottom: 1px solid $border;
  }
  
  .fsp-drop-area:hover {
    background: darken($secondary_bg, 4);
  }
  
  .fsp-content--transformer {
    padding: 0;
    height: 100%;
  }

  
  .ant-form-item-label {
    label {
      font-weight: 400;
    }
  }
  
  .picker-container {
    height: $height;
  }
}
