@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height) {
  .home {


    .max-width {
      max-width: $max_width;
      margin: 0 auto;
    }

    .dark-bg {
      background: #f9f9f9;
    }


    .join-btn,
    .learn-btn,
    .get-started-btn {
      font-weight: 600;
      font-size: $h4;
      height: 50px;
      transition: background 0.3s ease;
      min-width: 260px;
      border: 0;
      margin-bottom: 1rem;


      &:hover,
      &:focus {
        transform: scale(1.02);
      }

    }

    .join-btn {
      margin-right: 8px;
    }

    .get-started-btn {
      margin-top: calc(0.5*$vertical_padding);

      margin-bottom: 0;
    }

    .announcement {
      width: 100%;
      background: rgba(13, 163, 128, 0.1); //$success;
      font-weight: 600;
      font-size: $h4;
      line-height: $h4_line_height;
      text-align: center;
    }

    .introduction {
      background-image: url("../../../public/img/hero.webp");
      width: 100vw;
      background-size: cover;
      background-position: right center;
      align-items: center; // Vertically center content
      justify-content: space-between; // Distribute space between text and image

      .introduction-row {
        align-items: center;
      }

      .introduction-main-col {
        padding: $vertical_padding 0 $vertical_padding;
        max-width: 650px; // Keep the text area a manageable size
        color: white; // Ensure text stands out against the background
      }

      .introduction-title {
        color: white;
        width: 100%;
        font-size: $h1;
        line-height: $h1_line_height;
        font-weight: 600;
        margin-bottom: $h4_line_height;
        margin-top: $h4_line_height;
        // // font-family: 'Cooper LT BT';
        // letter-spacing: 2px;

      }

      .introduction-description {
        color: rgba(255, 255, 255, 0.9);
        font-size: $h3;
        line-height: $h3_line_height;
        margin-bottom: $h3_line_height;
        max-width: 600px;
      }

      .ant-wave {
        display: none;
      }



    }

    .testing {
      padding: $vertical_padding 0;
      // background-color: #f8f8f8;

      .test-catalog-container {
        padding: calc($vertical_padding / 2) $horizontal_padding 0;
        max-width: $max_width;
        margin: 0 auto;
      }
    }

    .testimonials {
      padding: $vertical_padding 0;
      max-width: 100%;

      .testimonials-container {
        padding: calc($vertical_padding / 2) $horizontal_padding 0;
        max-width: $max_width;
        margin: 0 auto;
      }
    }

    .faq {
      padding: $vertical_padding 0;
    }

    .memberships-container {
      padding: $vertical_padding 0;
    }

    .membership-plan:last-child {
      margin-right: -12px;
    }

    .membership-plan {
      // padding: 0px $horizontal_padding;
      margin-bottom: 14px; // Ensures space between rows


      .membership-plan-container {
        background-color: #fff;
        padding: $h3_line_height $h3;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        // margin-right: 12px;

        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        // max-width: 900px;
      }

      .membership-plan-left {
        flex: 1;
        margin-right: 20px;
      }

      .membership-plan-right {
        flex: 1;
        display: flex;
        flex-direction: column;
      }


      .membership-plan-title {
        font-size: $h2;
        line-height: $h2_line_height;
        font-weight: 600;
        margin-bottom: 10px;
        // font-family: 'Cooper LT BT';
        // letter-spacing: 1px;
      }

      .membership-plan-description {
        font-size: $h3;
        line-height: $h3_line_height;
        margin-bottom: 20px;
      }

      .membership-plan-price {
        font-size: $h2;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .membership-plan-price span {
        font-size: 16px;
        font-weight: normal;
      }

      .membership-plan-cta {
        text-align: center;
      }

      .join-btn {
        width: 100%;
        height: 50px;
        font-size: 16px;
      }

      .membership-features-container {
        list-style-type: none;
        padding-top: $h3_line_height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
      }

      .membership-features-container .feature-item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        font-size: $h5;
        cursor: pointer;
      }


      .membership-features-container .feature-item .checkmark {
        color: gray;
        font-size: $h5;
        margin-right: 5px;
        cursor: pointer;
      }

    }


    .memberships-container {
      max-width: $max_width;
      // padding: 0 $horizontal_padding;
      margin: auto;


      .membership-plans-container {
        padding: 0 $horizontal_padding;
      }

      .overview-subtitle {
        font-weight: normal;
        font-size: $h3;
        line-height: $h3_line_height !important;
        padding-bottom: calc($vertical_padding/2);
      }
    }


    .member-spotlights-container {
      padding: $vertical_padding 0;
      background: $success_bg;

    }

    .member-spotlights {
      padding: 0 $horizontal_padding;
      max-width: $max-width;
      margin: 0 auto;
    }

    .member-spotlight {

      background-color: #fff;
      padding: $h3_line_height;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin: 10px 0px;

      .quote-container {
        background-color: #f9f9f9;
        border-left: 5px solid $success;
        padding: 30px 20px 20px 40px;
        margin: 30px 0;
        // font-style: italic;
        font-size: 1.2em;
        line-height: 1.6;
        position: relative;
        color: #333;
      }

      .quote-container:before {
        content: '“';
        /* Opening quotation mark */
        font-size: 3em;
        /* Larger size for emphasis */
        color: $success;
        /* Same color as the border */
        position: absolute;
        /* Position absolutely to place it correctly */
        top: 5px;
        /* Align with text */
        left: 10px;
        /* Align with text */
      }

      .quote-container:after {
        content: '”';
        /* Closing quotation mark */
        font-size: 3em;
        /* Larger size for emphasis */
        color: $success;
        /* Same color as the border */
        position: absolute;
        // /* Position absolutely to place it correctly */
        // bottom: 10px;
        /* Align with text */
        right: 10px;
        /* Align with text */
      }

      .member-photo {
        margin: 16px 0px;
      }

      .circle-avatar {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }

      .timeline-title {
        font-size: $h3;
        line-height: $h3_line_height;

        .member-name {
          font-weight: 600;
          margin: $h4 0;
        }

        .caption {
          background-color: lightyellow;
          padding: 2px 10px;
          margin-right: 7px;
          font-size: $h4;
          line-height: $h4_line_height;
          text-transform: uppercase;
        }
      }

      .timeline-line {
        color: black;
        margin: 15px 0;
      }

      .timeline-reason {
        font-size: $h4;
        padding: 10px 0px 10px;
        // text-align: center;

        span {
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .timeline {
        padding: 25px 0px 0px;

        .svg {
          width: $h4;
          line-height: $h4_line_height;
          color: black;
        }

        .info-icon {
          font-size: $h5;
          line-height: $h5_line_height;
          color: gray;
          margin-left: 2px;
        }

        .timelime-item-title {
          font-weight: 600;
          font-size: $h4;
          line-height: $h4_line_height;
        }

        .timelime-item-description {
          font-size: $h5;
          line-height: $h5_line_height;

        }

        .ant-timeline-item-last {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .calendly-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust based on your layout needs */
  }


  .ant-popover {
    max-width: 300px;
  }

  .pop-content {
    padding: 0;

    .pop-title {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: $h4;
    }

    .pop-description {
      font-size: $h4;
    }
  }

  .pop-btn {
    height: 38px !important;
    padding: 0 15px !important;
    font-size: $h5 !important;
    font-weight: 600;
  }

  .pop-btn-icon {
    margin-left: 5px;
    margin-right: -5px;
  }

  .research-section {
    padding: $vertical_padding 0;
    max-width: 100%;

    .research-container {
      padding: calc($vertical_padding / 2) $horizontal_padding 0;
      max-width: $max_width;
      margin: 0 auto;
    }
  }

  .stats-section {
    // padding: 1.5rem 0;

    .metric {
      text-align: center;
      padding: 1.5rem;
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-5px);
      }

      .metric-icon {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        display: none;
      }

      .metric-number {
        font-size: calc($h3);
        font-weight: 700;
        // font-family: 'Courier New';
        // font-family: 'Cooper LT BT', Courier New;
        letter-spacing: 1px;
        margin-bottom: 0.25rem;
      }

      .metric-label {
        font-size: 1rem;
        color: #666;
      }
    }

    .animate-in {
      opacity: 0;
      animation: fadeIn 0.5s ease-in forwards;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .view-more-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: calc(0.5*$vertical_padding);


    .view-btn,
    .subscribe-btn {
      display: flex;
      align-items: center;
      height: 50px;
      font-size: $h4;
      padding: 0 24px;

      // .anticon {
      //   margin-right: 6px;
      // }
    }
  }

  .longevity-plan-section {
    text-align: center;
    padding: $vertical_padding 0;

    h2 {
      font-size: $h2;
      line-height: $h2_line_height;
      font-weight: 600;
    }

    .overview .overview-title-base {
      margin-right: auto;
      margin-left: auto;
    }

  }

  .longevity-plan-steps {
    padding: calc($vertical_padding / 2) $horizontal_padding;
    max-width: $max_width;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: $max_width;
    margin: 0 auto;

    .step {
      flex: 1;
      justify-content: center;
      margin: 10px 25px;
      text-align: center;

      &:last-child {
        margin-right: 0px;
      }

      &:first-child {
        margin-left: 0px;
      }
    }

    .step-image-wrapper {
      border-radius: 5px;
      width: 100%;

      .step-image {
        border-radius: 5px;
        width: 100%;
      }
    }

    .step-content {
      text-align: left;

      .step-number {
        font-size: $h5;
        line-height: $h5_line_height;
        margin-top: 12px;
        text-align: left;
        letter-spacing: 1px;
        color: #666;
      }

      h3 {
        font-size: $h3;
        line-height: calc($h3*1.2);
        margin-bottom: 5px;
        font-weight: 600;
        margin-top: 12px;
      }

      p {
        font-size: $h5;
        color: #666;
      }
    }
  }



  @media (max-width: 768px) {
    .longevity-plan-steps {
      flex-direction: column;


      .step {
        max-width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        align-items: flex-start;

        margin-right: 0px;
        margin-left: 0px;
      }


      .step-image-wrapper {
        width: 40%;
        margin-right: 18px;
      }


      .step-image {
        width: 100%;
        height: auto;
      }

      .step-content {
        width: 60%;
        margin-top: 0px;

        h3 {
          margin: 8px 0px;

        }

        p {
          margin: 8px 0px;
        }

        .step-number {
          margin-top: 0;
        }

      }
    }

  }

}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height );


  .introduction {
    background-image: url("../../../public/img/hero.webp");
    background-size: cover;
    background-position: center center;
    padding: 20px;
    text-align: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5); // Adjust transparency as needed
      z-index: 1;
    }

    .introduction-title,
    .introduction-description,
    .join-btn,
    .learn-btn,
    .accepted-insurance,
    .fsa-hsa-badge {
      position: relative;
      z-index: 2; // Ensures the text is above the overlay
    }

    .join-btn,
    .learn-btn {
      width: 100%; // Full width buttons on mobile
      margin-bottom: 0.75rem;
    }

    .fsa-hsa-badge-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

    }

  }


}


@media screen and (min-width: 1600px) {
  .introduction {
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 65vh;

  }
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height );


}