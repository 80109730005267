@import '../../../../colors.scss';

.gift {
    .multiple-input-form {
        width: 100%;
    }

    .field-container {
        position: relative;
    }

    .multiple-input {
        &-item {
            margin-bottom: 10px;
        }

        &-field {
            background: #f8f8f8;
            font-size: 18px;
            height: 52px;

            &:hover {
                background: $green_bg;
                border: 1px solid $success_light;
            }

            &:focus {
                background: $green_bg;
                border: 1px solid $success;
            }
        }
    }

    .field-suffix {
        position: absolute;
        top: 11px;
        right: 10px;
        font-size: 18px;
        height: 100%;
        color: $secondary_text;
    }

    .suffix-item {
        position: absolute;
        right: 4px;
        top: 34px;
        width: 100px;
    }

    .ant-picker {
        width: 100%;

        .ant-picker-input>input {
            font-size: 16px !important;
        }

        .ant-picker-input>input::placeholder {
            font-size: 16px !important;
        }
    }

    .field-group {
        margin-bottom: 24px;

        .group-label {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 16px;

            .anticon {
                color: black;
                font-size: 9px;
                margin-right: 4px;
                margin-left: -16px;
            }
        }
    }

    .bundle-item {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .product-select-label {
        .option-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }


    .price-button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;

        .option-price {
            font-weight: 600;
            font-size: 16px;
        }



        .ant-radio-group {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 12px;

            .ant-radio-button-wrapper {
                border-radius: 8px !important;
                padding: 8px 16px;
                font-size: 16px;
                font-weight: 500;
                transition: all 0.2s ease;
                border: 1px solid #e8e8e8;

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                }

                &.ant-radio-button-checked {
                    color: $success;
                    border-color: $success;
                    background-color: $success_bg;
                    box-shadow: 0 2px 8px rgba($success, 0.2);
                }
            }

            .ant-radio-button-checked {

                color: $success;
                border-color: $success;
                background-color: $success_bg;

            }

        }


    }


    .option-title-container {

        &.bundles {
            min-height: 9em;
        }

        &.tests {
            min-height: 6em;
        }

        &.giftCards {
            min-height: 4em;
        }

        .option-title,
        .option-description {
            text-align: left;
        }

        overflow: visible;
        text-overflow: unset;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 767px) {
            // sm breakpoint
            height: unset;
            min-height: 0;
        }
    }



    .option-image {
        width: 100%;
        // aspect-ratio: 16/9;
        overflow: hidden;
        margin-bottom: 12px;
        border-radius: 8px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

        .ant-image {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 0.3s ease-in-out;
            border-radius: 8px;
        }
    }


    .item-container {
        width: 100%;
        margin-right: 0;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s, transform 0.3s;
        cursor: pointer;
        padding: 20px;

        .price-button-container {
            margin-top: 0px;
        }

    }


    .ant-radio-wrapper {
        width: 100%;
        margin-right: 0;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s, transform 0.3s;
        cursor: pointer;
        padding: 0px 20px;

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            transform: translateY(-5px);
        }


        .ant-radio {
            position: absolute;
            top: 20px;
            right: 20px;
            display: none;
        }


    }

    .product-grid-item {

        .option-title {
            font-weight: 600;
            padding: 15px 0px 0px;
            color: black;
            line-height: 1.2;
            // font-family: 'Cooper LT BT';
            // letter-spacing: 1px;

            // Two line ellipsis
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            // -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .ant-radio-wrapper {


            &:hover {
                border: 1px solid $success;
            }

            &.ant-radio-wrapper-checked {
                border: 1px solid $success;
                background-color: $success_bg;

            }
        }

        .product-select-label {
            width: 100%;
        }


    }

    .ant-tabs {
        margin-bottom: 24px;
        border: 0;

        .ant-tabs-nav {
            margin-bottom: 24px;
            border: 0;

            &::before {
                // Remove the bottom border of tabs nav
                border: none;
            }

            .ant-tabs-nav-list {
                gap: 12px; // Space between tabs
            }

            .ant-tabs-tab {
                padding: 8px 20px;
                background: #f5f5f5;
                border-radius: 100px;
                margin: 0; // Remove default margin
                border: none;
                transition: all 0.2s ease;

                .ant-tabs-tab-btn {
                    color: rgba(0, 0, 0, 0.65);
                    font-weight: 500;
                }

                &:hover {
                    background: #eaeaea;
                }

                &.ant-tabs-tab-active {
                    background: $success_bg;

                    .ant-tabs-tab-btn {
                        color: $success;
                        font-weight: 600;
                    }
                }
            }

            // Remove the animated ink bar
            .ant-tabs-ink-bar {
                display: none;
            }
        }
    }

    .gift-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 400px; // Adjust this value based on your needs
        text-align: center;
        color: $secondary_text;
    }

    .gift-bundle-description {
        max-height: none;
        overflow: visible;

        .bundle-item {
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .gift-card-amount {
        display: inline-flex;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 16px;

        button {
            min-width: 100px;
            padding: 12px 24px;
            border-radius: 8px;
            font-weight: 500;
            transition: all 0.2s ease;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            }

            &.selected {
                color: $success;
                border-color: $success;
                background-color: $success_bg;
                box-shadow: 0 2px 8px rgba($success, 0.2);
            }
        }
    }

    .gift-card {
        .ant-radio-group {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 16px;

            .ant-radio-button-wrapper {
                min-width: 100px;
                height: auto;
                padding: 12px 24px;
                border-radius: 8px !important;
                font-size: 18px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #e8e8e8;
                transition: all 0.2s ease;

                &:before {
                    display: none; // Remove the vertical separator
                }

                &:first-child {
                    border-radius: 8px !important;
                }

                &:last-child {
                    border-radius: 8px !important;
                }

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    border-color: $success;
                    color: $success;
                }

                &.ant-radio-button-wrapper-checked {
                    color: $success;
                    border-color: $success;
                    background-color: $success_bg;
                    box-shadow: 0 2px 8px rgba($success, 0.2);
                }
            }
        }
    }

}