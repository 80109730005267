@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h4,
    $h5,
) {

    .panels {
        max-width: $max_width;
        margin-left: auto;
        margin-right: auto;

        .tests-header {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: $vertical_padding;
        }

        .tests-header-border {
            height: 1px;
            background: $border;
            width: calc(50% - 90px);
        }

        .tests-header-text {
            font-size: $h4;
            font-weight: 600;
            background: white;
            width: 200px;
            margin: 0;
        }

        .panel {
            cursor: pointer;
            margin-top: calc($vertical_padding/2);
        }

        .panel-img-container {
            position: relative;
        }

        .panel-img {
            width: 100%;
        }

        .panel-icon {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }

        .panel-title {
            font-size: $h4;
            font-weight: 600;
            margin-top: calc($vertical_padding/8);
            margin-bottom: 0px;
        }

        .panel-description {
            font-size: $h5;
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.6);
        }

        .panel-discount-price {
            color: $success;
            font-size: $h4;
            font-weight: 600;
        }

        .panel-original-price {
            color: #ddd;
            font-size: $h4;
            font-weight: 600;
            text-decoration: line-through;
            margin-left: 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h4: $mobile_h4,
        $h5: $mobile_h5,
    );
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h4: $desktop_h4,
        $h5: $desktop_h5,
    );
}