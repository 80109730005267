@import '../../colors.scss';

$width: 750px;

.linkedin-icon {
  color: #0A66C2;  // LinkedIn's official blue
}

.facebook-icon {
  color: #1877F2;  // Facebook's official blue
}

.twitter-icon {
  color: #1DA1F2;  // Twitter/X's classic blue
}

.reddit-icon {
  color: #FF4500;
}

.email-icon,
.copy-icon {
  color: #bbb;
}

.blog {
  .blog-post {
    margin: 0 auto 60px auto;
  }

  .blog-intro {
    width: $width;
    max-width: 100%;
    margin: 0 auto;
    padding: 50px 10px 60px 10px;
  }

  .blog-body {
    width: $width;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 10px 0 10px;
  }

  .blog-image {
    width: 100%;
    margin: 20px 0 0 0;
    border-radius: 10px;
    border: 1px solid $border;
  }

  .blog-heading {
    font-size: 43px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    width: $width;
    margin: 0 auto;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-audio {
    height: 50px;
    width: 100%;
    margin: 25px auto 40px auto;
    display: block;
  }

  .blog-chart-list {
    
  }

  .blog-chart {
    width: 100%;
    margin-bottom: 30px;
  }

  .blog-subheading {
    font-size: 24px;
    line-height: 1.6;
    margin: 20px auto 0 auto;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 50px;
    margin-bottom: 15px;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 20px 0 25px 0;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-link {
    color: $success !important;
    border-bottom: 1px dotted $success;
    text-decoration: none;
  }

  .blog-table {
    .ant-table-thead tr:first-child .ant-table-cell:first-child {
      border-top-left-radius: 8px !important;
    }

    .ant-table-thead tr:first-child .ant-table-cell:last-child {
      border-top-right-radius: 8px !important;
    }
  }

  td.blog-cell-custom {
    padding: 0 !important;
  }

  .blog-cell-inner {
    padding: 8px 15px;
  }

  .blog-list {
    margin: 0;
    padding-left: 30px;
    max-width: 100%;
  }

  .blog-list li {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: $primary_text;
  }

  .blog-list li strong {
    font-weight: 700;
  }
}

.sticky-blog-header {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  padding: 15px 0;
  background: rgba(255,255,255,0.95);

  .sticky-blog-header-inner {
    width: $width;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 100%;
    white-space: nowrap;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
    scrollbar-width: none; // Hide scrollbar on Firefox
    -ms-overflow-style: none; // Hide scrollbar on IE/Edge
    
    &::-webkit-scrollbar {
      display: none; // Hide scrollbar on Chrome/Safari
    }
  }

  .blog-avatar {
    margin-right: 5px;
  }

  .blog-label {
    font-size: 14px;
    margin-right: 30px;
    color: $primary_text;
  }

  .blog-label .anticon {
    margin-right: 6px;
    transform: scale(1.2);
    color: $primary_text;
  }

  .blog-label-link {
    color: $success;

    .anticon {
      color: $success;
    }

    .blog-label {
      color: $success;
    }
  }  
}

.sources {
  .source-list {
    margin: 0;
    padding: 0 0 0 20px;
    font-size: 13px;
    line-height: 1.5
  }

  li {
    color: $secondary_text;
    margin-bottom: 10px;
  }

  a {
    color: $success;
    border-bottom: 1px dotted $success;
    text-decoration: none;
  }
}