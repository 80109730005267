@import '../../../colors.scss';

.pro-draw-flow {
  .panel-link {
    color: $success;
  }
}

.time-remaining {
  font-weight: bold;
  color: $warning; // Red color for urgency
  background: $warning_bg; // Light red background
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid $warning;
}

.timer-warning {
  background: $warning_bg;
  border-left: 4px solid $warning;
  padding: 12px;
  border-radius: 4px;

  .time-remaining {
    font-weight: 600;
    color: $warning;
  }
}