@import '../../colors.scss';
@import '../../global.scss';

.appointments {

  .appointment-status {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;

    // Status colors
    &.complete-status,
    &.completed-status {
      background: $success_bg;
      color: $success;
    }

    &.canceled-status,
    &.cancel-status {
      color: $error;
      background: $error_bg;
    }

    &.confirmed-status {
      color: $primary_text;
      background: $secondary_bg;
    }

    &.pending-status,
    &.collected-status {
      color: $warning;
      background: $warning_bg;
    }
  }

  .credit-count {
    background: rgba(255, 255, 255, 0.9);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    color: $success;
    font-size: 12px;
    font-weight: 600;
    margin-left: 3px;
  }

  // Show mobile cards ONLY on small screens
  @media screen and (max-width: 768px) {
    .mobile-view {
      display: block;
      padding: 16px;
    }

    .desktop-view {
      display: none;
    }
  }

  // Show table ONLY on larger screens
  @media screen and (min-width: 769px) {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }

  // Card styles for mobile view
  .appointment-card {
    margin-bottom: 16px;

    .ant-card-body {
      padding: 16px;
    }

    .appointment-status {
      text-transform: uppercase;
      font-size: 12px;
    }

    .appointment-card-content {
      .appointment-card-row {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.tests {
          font-weight: 600;
          font-size: 16px;
        }

        &.time,
        &.location {
          color: rgba(0, 0, 0, 0.65);

          .anticon {
            margin-right: 8px;
          }
        }
      }
    }

    .location {
      display: flex;
      align-items: flex-start;



      .location-text {
        flex: 1;
      }
    }
  }

  .time-column {
    white-space: nowrap;
  }

  .pending-message {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 0.85em;
    color: #6c757d;
    line-height: 1.4;
  }

  .appointment-status {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;

    &.pending-status {
      color: #f59e0b;
    }
  }

  .location-column {
    .facility-name {
      font-weight: 600;
      margin-bottom: 2px;
    }

    .address-line {
      color: #666;
      line-height: 1.4;
    }
  }
}