@import '../../../global.scss';
@import '../../../colors.scss';


@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .advice-section {
    background: $secondary_bg;
    border-radius: 10px;
    padding: 30px 30px;

    .advice-item-header {
      display: flex;
      align-items: baseline;
    }

    .index {
      margin-right: 0.5em;
      flex-shrink: 0;
    }

    .item-title {
      flex-grow: 1;
    }

    .ant-tabs-content-holder {
      background-color: transparent !important;
    }


    .advice-title {
      color: $primary_text;
      font-size: $h3;
      line-height: $h3_line_height;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .advice-description {
      color: $secondary_text;
      font-size: $h4;
      line-height: $h4_line_height;
      margin-bottom: 0;
    }


    .advice-list {
      padding-left: 15px;
      margin-top: 20px;
      margin-bottom: 0;
    }

    a {
      color: $success;

      &:hover {
        text-decoration: underline;
        color: $success;
      }

    }

    .advice-list li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .advice-item-title {
      color: $primary_text;
      font-weight: 600;
      padding-left: 5px;
      margin-bottom: $h5;

      .ant-collapse-header-text {
        font-size: $h4;
        line-height: calc(0.85*$h4_line_height);
      }

      span.index {
        font-weight: 500;
        margin-right: 5px;
      }
    }

    .advice-item-title:last-child {
      margin-bottom: 0px;
    }

    .advice-item-description {
      color: $secondary_text;
      font-size: $h4;
      line-height: $h4_line_height;
      font-weight: 400;
      margin-left: 1em;

      p:first-child {
        margin-top: 8px;
      }

      p:last-child {
        margin-bottom: 0px;
      }
    }

    .advice-list-one .advice-item-description {
      margin-left: 0px;
    }

    .contact-concierge {
      margin-top: 15px;
      padding: 10px;
      font-size: calc(0.9*$h5);
      line-height: calc(0.9*$h5_line_height);
      border-radius: 5px;
      border: 1px solid lightgray;
    }

    .ant-collapse .ant-collapse-header {
      padding: 0px 0px 0px !important
    }

    .ant-collapse .ant-collapse-item {
      border: 0 !important
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
      margin: 0 !important;
      // height: unset !important;
    }


    .foodTable.ant-table-wrapper .ant-table {
      border-radius: 0px !important;
      margin: 0;

    }

  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}