@import '../../colors.scss';

.klarna-form {
    margin: 0 auto;

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 48px;

        .spinner-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center !important;
            gap: 16px;
            margin-top: 1rem;
        }
    }

    .disclaimer {
        margin-top: 1rem;
        font-size: 12px;
        color: $secondary_text;
    }

    .confirm-btn,
    .skip-btn {
        width: 100%;
        margin-top: 1rem;
        font-weight: 600;
        padding: 0 15px;
        height: 40px;
        font-size: 16px;
    }

    .messaging-element-container {
        border: 1px solid $border;
        border-radius: 5px;
        background-color: $secondary_bg;

        .spinner-container {
            // margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

    }

    .StripeElement {
        padding: 16px 15px;


    }

    .new-window-text {
        display: flex;
        align-items: center;
        gap: 10px;
        // margin: 1rem 0;
        padding: 1rem;
        border-top: 1px solid $border;
        // border-radius: 5px;
        // margin-top: 1rem;
        // background-color: $secondary_bg;


        .new-window-icon {
            width: 60px;
            height: 60px;
        }
    }


}