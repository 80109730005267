@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .patient-catalog {

    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: #F9F9F9;
    height: 100%;


    .secondary-link {
      color: $secondary-text;
      text-decoration: underline;
    }

    .dashboard-main {
      padding: 30px 0px 0px 0px;
      //height: 1200px;
      border-right: 1px solid #F0F0F0;
    }

    .page-header {
      border: 0px;
      // padding-top: calc($vertical_padding/3);
    }

    .page-header .page-header-title {
      font-size: $h3;
      line-height: $h3_line_height;
    }

    .test-catalog-container {

      padding: 0px 15px;

      // .test-catalog .test-title {
      //   font-size: $h4;
      // }

    }

    .dashboard-box {
      border: 1px solid #F0F0F0;
      padding: 15px 20px;
      margin: 10px 0px 25px;
      border-radius: 5px;

    }

    .dashboard-title {
      font-weight: 600;
      font-size: $h3;
      line-height: $h3_line_height;
    }



    .dashboard-alert {

      padding: 15px 5px;

      // border: 1px solid black;
      background: $primary-bg;
      border: 1px solid rgba(108, 199, 211, 0.2);

      .dashboard-alert-title {
        font-weight: 700;
        font-size: 16px;
      }

      .dashboard-alert-description {
        margin: 5px 0px 0px;
      }

      .dashboard-alert-cta {
        vertical-align: middle;
      }

      .dashboard-alert-cta-mobile {
        margin-top: 15px;
      }

      .dashboard-alert-icon {
        text-align: center;
      }

      .main-cta-btn {
        font-size: 14px;
        height: 40px;
      }
    }

    .dashboard-feed-item {
      margin: 10px 0px;
      padding: 20px 0px 20px;
      border-bottom: 1px solid #F0F0F0;


      .dashboard-feed-item-title {
        font-weight: 500;
        font-size: $h4;
        line-height: $h4_line_height;
      }

      .dashboard-feed-item-content {
        margin: 10px 0px;
        color: $secondary_text;
        font-size: $h5;
        line-height: $h5_line_height;
      }

      .dashboard-feed-item-date {
        font-size: calc(0.8*$h5);
        color: $secondary-text
      }
    }

    .dashboard-feed-item:first-child {
      padding-top: 10px;
    }

    .dashboard-feed-item:last-child {
      border: 0px;
      margin-bottom: 0;
    }


    .blog-list .blog-item:first-child {
      padding-top: 20px;
    }


    .blog-list .blog-title {
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      font-family: unset;
    }

    .blog-list .blog-content {
      font-size: unset;
      line-height: 20px;
      color: unset;
    }

    .blog-list .blog-published {
      color: $secondary-text;
      font-size: 14px;
    }

    .blog-list .blog-item-footer {
      font-size: 14px;
    }

    .blog-list .blog-item {
      border-color: #F0F0F0; //$secondary-bg
    }

    .blog-list .blog-subject {
      display: none;
    }

    // .blog-list .blog-image {
    //   display: none
    // }

    .blog-list .blog-image img {
      width: 100%;
      height: auto;
      overflow: hidden;
    }

    // .blog-list .blog-text {
    //   padding-right: unset !important;
    // }

    .blog-list .blog-item:last-child {
      border: none;
    }

  }

  @media only screen and (min-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border-right: 1px solid #F0F0F0;
      min-height: 1200px;
    }
  }

  @media only screen and (max-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border: 0;
      padding: calc($vertical_padding/2) 0 0 0;
      // margin-top: -25px;
    }


  }

  @media only screen and (max-width: $breakpoint_mobile) {

    // .dashboard {
    //   padding-top: $h1;
    // }

    .dashboard .dashboard-alert {
      padding: 10px 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}