@import '../../global.scss';
@import '../../colors.scss';

.admin-tasks {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 10px;
  }

  .settings-btn {
    background: white !important;
  }

  .patient-link,
  .task-title {
    color: $success;

    p, span {
      color: $success !important;
      margin: 0;
    }
  }

  td.photo-col {
    padding-top: 8px !important;
    padding-bottom: 0px !important;
  }

  .user-photo {
    width: 26px;
    height: auto;
  }

  .primary-table .ant-table-tbody>tr.deadline-passed>td {
    background-color: $error_bg !important; // or any color that fits the design for urgency
  }

  .deadline-passed {
    background-color: $error_bg !important; // Light red background to indicate urgency
  }

  .strikethrough-row td {
    text-decoration: line-through;
    color: #ccc; // Grey out the text to indicate completion.

    * {
      color: inherit !important; // Ensure links and other elements match the text color
      text-decoration: inherit !important;
    }

    // Specific style for checkboxes to make them appear disabled or distinct when the task is completed
    .ant-checkbox-wrapper {
      opacity: 0.5; // Lower the opacity to give a disabled look
    }
  }
}