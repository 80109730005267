@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import './colors.scss';
@import './global.scss';
@import './mixins.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  @font-face {
    font-family: 'Cooper LT BT';
    src: url('/assets/fonts/CooperLightBT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cooper LT BT';
    src: url('/assets/fonts/CooperLightItalicBT.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  * {
    box-sizing: border-box;
  }

  *,
  .ant-typography {
    font-family: 'Source Sans 3', sans-serif;
  }

  .hide {
    display: none;
  }

  html {
    width: 100%;
    overflow-x: hidden;
  }

  html {
    position: relative;
  }

  html,
  body,
  #root,
  body,
  .App {
    min-height: 100svh;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  html,
  body,
  .ant-layout {
    background: rgba(0, 0, 0, 0);
    font-family: 'Source Sans 3', 'Lato', sans-serif !important;
  }

  // h1 {
  //   font-family: 'Cooper LT BT';
  // }

  .secondary-link {
    color: $secondary_text !important;
  }

  .test-link {
    color: $primary_text !important;
    text-decoration: underline !important;
    text-decoration-style: dotted !important;

    &:hover,
    &:focus {
      color: $primary_text !important;
    }

    &:active {
      color: $secondary_text !important;
    }
  }

  .component-layout {
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  // Ant Design Table

  .ant-table-filter-dropdown {
    .ant-dropdown-menu-item {
      background: white !important;

      &:hover {
        background: $secondary_bg !important;
      }
    }

    .ant-checkbox-wrapper {
      padding: 3px 0;

      &:hover .ant-checkbox-inner {
        border-color: $success;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: $success !important;
      border: 1px solid $success !important;
    }

    button {
      height: 30px;
      font-weight: 600;
      width: 100%;
    }

    button.ant-btn-default {
      &:hover {
        color: $success !important;
        border: 1px solid $success !important;
      }

      &:active {
        color: darken($success, 10) !important;
        border: 1px solid darken($success, 10) !important;
      }
    }

    .ant-space {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }

    .ant-space .ant-space-item {
      width: 50%;
    }
  }

  .ant-table-filter-trigger.active svg {
    color: $success;
  }

  .ant-pagination-item-container .anticon {
    color: $success !important;
  }

  .ant-pagination-item-active {
    border: 1px solid $success !important;

    a {
      color: $success !important;
    }

    &:hover {
      border: 1px solid $success !important;

      a {
        color: $success !important;
      }
    }

    &:active {
      border: 1px solid darken($success, 10) !important;

      a {
        color: darken($success, 10) !important;
      }
    }
  }

  // Width

  .max-width {
    max-width: $max_width;
    margin: 0 auto;
    padding: 0 $horizontal_padding;
  }

  // Video

  .video-container {
    background: rgba(0, 0, 0, 0.85);
  }

  // Responsive

  @media (min-width: 992px) {
    .hide-desktop {
      display: none;
    }
  }

  @media (max-width: 992px) {
    .hide-mobile {
      display: none;
    }
  }

  // Images

  img {
    pointer-events: none;
  }

  // Ant notification
  .ant-notification-notice {
    .ant-btn-link {
      color: $success;

      &:hover {
        color: $success !important;
      }

      &:active {
        color: darken($success, 10) !important;
      }
    }
  }

  .ant-notification-notice-icon-success.anticon {
    color: $success !important;
  }

  .notification-description {
    color: $secondary_text;
    font-size: 14px;
  }

  .notification-btn {
    margin-top: 20px;
    display: block;
    font-weight: 600;
    font-size: 14px;
    height: 42px;
    padding: 0 20px;
  }

  // Ant message

  .ant-message-success,
  .ant-message-error {
    z-index: 100;
  }

  .ant-message-success svg {
    color: $success;
  }

  .ant-message-error svg {
    color: $error;
  }

  // Ant forms

  .ant-form-item .ant-form-item-label>label {
    color: $primary_text;
    font-weight: 600;
    font-size: 14px;
  }

  .ant-input[disabled] {
    border-color: $border;
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      border-color: $border;
    }
  }

  .ant-btn-primary:not([disabled]) {
    background: $success;


    &:hover {
      background: $success !important;
      opacity: 0.9;
    }

    &:active {
      background: darken($success, 5) !important;
    }
  }

  .ant-btn-primary:disabled {
    border-color: $border;
    background: rgba(255, 255, 255, 0.1);
    color: $secondary_text;
  }

  .ant-input-password {

    &.ant-input-outlined:focus-within,
    &:hover {
      border-color: $success;
      box-shadow: none;
    }

    svg {
      margin-right: -5px !important;
    }
  }

  .ant-input {
    background: white;
    padding: 10px 15px;
    color: $primary_text;
    border-color: $border;
    font-family: 'Source Sans 3', sans-serif;

    &::placeholder {
      color: $secondary_text;
    }

    &:focus,
    &:hover {
      border-color: $success;
      box-shadow: none;
    }

    svg {
      color: $secondary_text;
      margin-right: 10px;
      transform: scale(1.2);
    }
  }

  .ant-input-affix-wrapper {
    background: $secondary_bg;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 15px;
    border-color: $border;

    ::placeholder {
      color: $secondary_text;
    }

    svg {
      color: $secondary_text;
      margin-right: 10px;
      transform: scale(1.2);
    }

    input {
      background: rgba(255, 255, 255, 0);
      color: white;
    }
  }

  .ant-input-affix-wrapper-status-error,
  .ant-input-status-error {
    border-color: $error !important;
  }

  .ant-form-item .ant-form-item-explain-error {
    color: $error;
  }

  .prev-btn {
    position: relative;
    padding: 0 30px 0 40px;
    height: 44px;
    font-weight: 700;
    background: rgba(0, 0, 0, 0);
    border: 1px solid $border;

    .prev-btn-text {
      border-left: 1px solid $border;
      color: $secondary_text;
      padding-left: 30px;
      padding-top: 10px;
      display: inline-block;
      margin-left: 10px !important;
      height: 100%;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover {
      border-color: $success !important;

      .prev-btn-text {
        border-left: 1px solid $success;
        color: $success;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .arrow-icon {
        color: $success;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &:active {
      border-color: darken($success, 15) !important;

      .prev-btn-text {
        border-left: 1px solid darken($success, 15);
        color: darken($success, 15);
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .arrow-icon {
        color: darken($success, 15);
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    .anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
      color: $secondary_text;
    }

    .arrow-icon {
      left: 18px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.ant-btn-loading {
      .arrow-icon {
        display: none;
      }
    }
  }

  // Ant picker

  .ant-picker {
    background: $secondary_bg;
    padding: 10px 15px;
    color: $primary_text;
    border-color: $border;
    width: 100%;

    &.ant-picker-status-error {
      background: $secondary_bg !important;
      border-color: $error !important;
    }

    input {
      color: $primary_text !important;
    }

    input::placeholder {
      color: $secondary_text !important;
    }

    .ant-picker-suffix {
      color: $secondary_text;
    }
  }

  // Ant select

  $select_height: 44px;

  .ant-select:not(.ant-select-multiple) {
    .ant-select-selector {
      height: $select_height !important;
    }
  }

  .ant-select {
    height: 43px;

    &.ant-select-focused .ant-select-selector {
      box-shadow: none !important;
      border: 1px solid $success !important;
    }

    .ant-select-selector {
      height: 43px !important;
      background: white !important;
      padding: 0px 15px 0 15px !important;
      color: $primary_text !important;
      border-color: $border !important;

      .ant-select-selection-search {
        height: 43px;
        inset-inline-start: 15px;
        inset-inline-end: 5px;
      }

      .ant-select-selection-placeholder {
        color: $secondary_text;
        padding: 6px 0;
        font-size: 14px !important;
      }

      .ant-select-selection-search-input {
        height: $select_height !important;
        font-size: 14px !important;
      }

      .ant-select-selection-item {
        height: 43px;
        padding: 7px 0;
        color: $primary_text;
      }
    }

    &.ant-select-status-error .ant-select-selector {
      border-color: $error !important;
    }

    .anticon-loading svg {
      color: $secondary_text;
    }

    .ant-select-suffix {
      color: $secondary_text;
    }
  }

  .ant-select-multiple {
    .ant-select-selector {
      padding: 0px 4px;
    }

    .ant-select-selection-item {
      padding: 4px 5px !important;
      margin: 0;
      background: white;
      height: auto;
    }

    .anticon-close {
      color: $primary_text;
      margin-left: 3px;

      &:hover {
        color: $secondary_text;
      }
    }

    .ant-select-selection-search-input {
      margin-top: -1px !important;
    }

    .ant-select-selection-overflow-item {
      padding-right: 3px;
      margin-left: 5px;
    }
  }

  .ant-select-dropdown {
    background: $secondary_bg;
    border: 1px solid $border;
    z-index: 9001 !important;

    .anticon-check {
      color: $success;
    }

    .ant-select-item {
      background: rgba(0, 0, 0, 0) !important;
    }

    .ant-select-item:hover {
      background: darken($secondary_bg, 3) !important;
    }

    .ant-select-item-option-selected {
      background: $success_bg !important;
    }

    .ant-select-item-option-content {
      color: $primary_text !important;
    }
  }

  // Buttons

  .ant-btn-default:not(:disabled):not(.ant-btn-primary) {
    &:hover {
      color: $success;
      border-color: $success;
    }

    &:active {
      color: darken($success, 5);
      border-color: darken($success, 5);
    }
  }

  // Menu

  .ant-dropdown-menu-title-content a {
    font-size: 14px !important;
    font-weight: 400;
  }

  // Ant table

  .primary-table {
    .ant-table-thead .ant-table-cell {
      background: $secondary_bg;
    }

    .ant-table-cell {
      background: white;
    }

    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
      background: white;
    }

    .ant-table-tbody {
      .ant-table-cell {
        background: white !important;
      }

      .ant-table-cell-row-hover {
        background: $secondary_bg !important;
      }
    }

    .ant-table-cell .ant-dropdown-trigger {
      background: rgba(0, 0, 0, 0);
    }
  }

  .secondary-table {
    .ant-table-thead .ant-table-cell {
      background: $secondary_bg;
    }

    .ant-table-cell {
      background: $secondary_bg;
    }

    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
      background: $secondary_bg;
    }

    .ant-table-tbody {
      .ant-table-cell {
        background: $secondary_bg !important;
      }

      .ant-table-cell-row-hover {
        background: white !important;
      }
    }

    .ant-table-cell .ant-dropdown-trigger {
      background: rgba(0, 0, 0, 0);
    }
  }

  .ant-table-cell {
    color: $primary_text;
    border-radius: 0 !important;
  }

  .ant-table-wrapper th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .ant-table-wrapper .ant-table-thead>tr>th,
  .ant-table-wrapper .ant-table-thead>tr>td {
    color: $primary_text;
    border: 0;
    font-size: 14px;

    &::before {
      display: none;
    }
  }

  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr>td {
    border-top: 1px solid $border;
    border-bottom: 0;
    padding: 10px;
  }

  th {
    border-bottom: 0px !important;
  }

  .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr:last-child>td {
    border-top: 1px solid $border;
    border-bottom: 0;
  }

  .ant-table-cell {
    padding-left: 15px !important;
    font-size: 14px;
  }

  .ant-table-cell .edit-btn,
  .ant-table-cell .ant-dropdown-trigger {
    background: white;
    color: $primary_text;
    border: 1px solid $border;
    padding: 0 8px;
    height: 30px;
    font-size: 14px;

    &:hover,
    &:focus {
      border-color: $success !important;
      color: $success !important;
    }

    &:active {
      border-color: darken($success, 5) !important;
      color: darken($success, 5) !important;
    }
  }

  .ant-table-tbody>tr>td.action-column {
    padding: 6px 10px 6px 0 !important;
    text-align: right;

    button {
      background: white !important;
    }
  }


  // Timeline

  .ant-timeline {
    .ant-timeline-item {
      padding-bottom: 30px;
    }

    .ant-timeline-item-head {
      background: rgba(0, 0, 0, 0);
      color: $secondary_text;
    }

    .ant-timeline-item-content {
      margin-top: -3px;
    }

    .ant-timeline-item-title {
      color: $primary_text;
      font-size: 13px;
    }

    .ant-timeline-item-tail {
      border-color: $border;
    }

    .ant-timeline-item-paragraph {
      color: $secondary_text;
    }
  }

  // Ant modal

  .ant-modal {
    border-radius: 10px;
    border: 1px solid $border;
    padding-bottom: 0;

    .ant-modal-content {
      background: white;
    }

    .ant-modal-header {
      background: white;
    }

    .ant-modal-title {
      color: $primary_text;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 18px;
      // text-decoration: underline;
    }

    .modal-description {
      color: $secondary_text;
      font-size: 18px;
      margin-top: -10px;
    }

    .ant-modal-close-icon {
      color: $secondary_text;
    }

    .ant-modal-footer button {
      font-weight: 500;
      font-size: 14px;
    }
  }

  // Address autocomplete

  .street-address-container {
    position: relative;
  }

  .street-address-container input {
    z-index: 5 !important;
  }

  .street-address-container .adm-list-item {
    background: rgba(0, 0, 0, 0);
  }

  .street-address-container .adm-list-item-content-main {
    padding-top: 0;
    padding-bottom: 0;
  }

  .autocomplete-dropdown-overlay {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    left: 0px;
    width: 100%;
    background: $secondary_bg;
    border-radius: 0 0 5px 5px;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0 1px 2px #ddd;
  }

  .address-spinner {
    color: $primary_text;
    font-size: 13px;
    padding: 10px 20px;

    .ant-spin {
      margin-right: 10px;
      float: left;
    }
  }

  .suggestion-item {
    font-size: 13px;
    color: $primary_text;
    padding: 5px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    $primary_text-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;

    &:active {
      background: $secondary_bg;
    }
  }


  // Ant radio group

  .ant-radio-group {
    .ant-radio-wrapper {
      border: 1px solid $border;
      border-radius: 5px;
      padding: 20px;
      display: block;
    }

    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: $success;
    }

    .ant-radio-wrapper-checked {
      border-color: $success;
      background: white;
    }

    .ant-radio {
      margin-bottom: -3px;
    }

    .ant-radio-inner {
      background: $secondary_bg;
      border-color: $border;
    }

    .ant-radio-checked .ant-radio-inner {
      background: rgba(255, 255, 255, 0.1);
      border-color: $success;

      &::after {
        background: $success;
      }
    }
  }

  // Ant drawer

  .ant-drawer {
    .ant-drawer-content-wrapper {
      border-left: 1px solid $border;
    }

    .ant-drawer-content {
      background: white;
    }

    .ant-drawer-header {
      border-bottom: 1px solid $border;
    }

    .ant-drawer-title {
      color: $primary_text;
      font-size: 14px;
    }

    .ant-drawer-close {
      color: $secondary_text;
      font-size: 14px;

      &:hover,
      &:focus {
        color: $secondary_text;
      }

      &:active {
        color: darken($secondary_text, 30);
      }
    }
  }

  // Ant tabs

  .ant-tabs {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav {
    border-bottom: 1px solid $border;

    .ant-tabs-tab+.ant-tabs-tab {
      margin-left: 5px;
    }

    .ant-tabs-tab {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      color: $secondary_text;
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus {
    color: $success !important;
  }

  .ant-tabs-ink-bar {
    background: $success !important;
  }

  // React big calendar

  .rbc-time-view {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $border;
  }

  .rbc-toolbar-label {
    color: $primary_text;
    font-size: 13px;
    margin-top: -2px;
    text-align: left !important;
  }

  .rbc-time-header {
    border-right: 0px !important;

    * {
      border: 0px !important;
    }
  }

  .rbc-button-link {
    color: $primary_text;
  }

  .rbc-label {
    color: $primary_text;
  }

  .rbc-header {
    border-bottom: 0 !important;
    border-left: 0 !important;
  }

  .rbc-today {
    background: rgba(255, 255, 255, 0.1);
  }

  .rbc-time-content {
    border-top: 1px solid $border;
  }

  .rbc-timeslot-group {
    border-color: $border;
    border-left: 1px solid $border;
    border-bottom: 0;
  }

  .rbc-time-gutter .rbc-timeslot-group {
    border-left: 0;
  }

  .rbc-day-slot .rbc-time-slot {
    border-color: $border;
  }

  .rbc-day-slot .rbc-timeslot-group:first-child .rbc-time-slot:first-child {
    border-top: 0;
  }

  .rbc-addons-dnd-row-body {
    display: none;
  }

  .rbc-current-time-indicator {
    background: $success;
  }

  .rbc-btn-group button {
    border-color: $border;
    color: $primary_text;
    cursor: pointer;
    background: rgba(0, 0, 0, 0) !important;

    &:hover,
    &:focus {
      border-color: $border;
      color: $primary_text;
    }

    &:active {
      border-color: $success !important;
      color: $success !important;
    }
  }

  .event-appointment {
    position: relative;
    background: white !important;
    border-color: $border !important;
    padding: 0;

    .draw-range-container {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      // height: 20px;
      background: $success_bg;
      z-index: 0;
      padding: 4px 5px;
    }

    .draw-range {
      color: $success;
      font-size: 10px;
    }

    .rbc-event-label {
      color: $primary_text;
      padding-left: 5px;
      padding-top: 5px;
      // font-size: 12px;
    }
  }

  .event-patient-name {
    color: $success;
    display: block;
    margin-top: 4px;
    position: relative;
    z-index: 1;
    padding-left: 5px;
  }

  .event-appointment-location {
    color: $secondary_text;
    font-size: 10px;
    position: absolute;
    bottom: 0px;
    left: 5px;
  }

  .event-phlebotomist-name {
    color: $secondary_text;
    font-size: 10px;
    position: absolute;
    bottom: 0px;
    right: 5px;
  }

  .rbc-event-continues-later {

    .event-phlebotomist-name,
    .event-appointment-location {
      display: none;
    }
  }

  // Risk tag

  .risk-tag {
    padding: 3px 10px;
    min-width: 60px;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    // font-weight: 500;
    display: inline-block;
  }

  .optimal-risk {
    color: $success;
    background: $success_bg
  }

  .abnormal-risk,
  .high-risk {
    color: $error;
    background: $error_bg;
  }

  .moderate-risk {
    color: $warning;
    background: $warning_bg;
  }

  // Ant progress
  .ant-progress-status-success {
    .ant-progress-circle-path {
      stroke: $success !important;
    }

    .anticon-check {
      color: $success;
      transform: scale(1.3);
    }
  }

  .ant-progress-show-info:not(.ant-progress-status-success) {
    .ant-progress-circle-path {
      stroke: $success !important;
    }
  }

  .ant-progress-text {
    font-size: 8px;
    color: $secondary_text !important;
  }

  // Popover

  .ant-popover {
    .ant-popover-title {
      color: $primary_text;
    }
  }

  // Spinner

  .ant-spin-dot-item {
    background-color: $success !important;
  }

  // Links

  a.primary,
  .primary-link {
    color: $success !important;

    &:hover {
      color: $success !important;
    }

    &:active {
      color: darken($success, 15) !important;
    }
  }



  // Switch

  .ant-switch-checked {
    background: $success !important;
  }


  // Tooltip
  .ant-tooltip {
    z-index: 10;
  }

  .ant-tooltip-placement-left .ant-tooltip-inner {
    margin-right: -1px;
  }

  .ant-tooltip-placement-right .ant-tooltip-inner {
    margin-left: -1px;
  }

  .ant-tooltip-inner {
    font-size: 14px;
    min-height: 28px !important;
  }

  // Collapse

  .ant-space-compact .ant-form-item:last-child {
    margin-left: -1px;
  }

  // Quill editor

  .ql-editor {
    * {
      font-size: 14px;
    }
  }

  .ql-toolbar.ql-snow {
    border-left: 1px solid $border !important;
    border-right: 1px solid $border !important;
    border-bottom: 0 !important;
    border-top: 1px solid $border !important;
    border-radius: 5px 5px 0 0;
  }

  .ql-container.ql-snow {
    border: 1px solid $border !important;
    border-radius: 0 0 5px 5px;
  }

  // Flow

  .flow-intro {
    background: linear-gradient(to bottom right, rgba(13, 163, 128, 0.4), rgba(13, 163, 128, 0.1));
    height: 100%;
    position: relative;

    .flow-intro-body {
      background: white;
      max-width: 600px;
      margin: 0 auto;
      padding-bottom: 100px;
      height: 100%;
      position: relative;
      padding: 60px 20px 140px 20px;

      .flow-product-pricing {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $h3;
        line-height: $h3_line_height;
        font-weight: 600;
        margin: auto;

        .product-original-price {
          margin-right: 5px;
        }

        .product-member-price {
          color: red;
          font-weight: bold;
          margin-left: 5px;
        }

        .member-tag {
          margin-left: 10px;
          color: red;
          border: 1px solid red;
          background-color: transparent;
        }
      }
    }

    .flow-title {
      text-align: center;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 15px;
      line-height: 32px;
    }

    .flow-description {
      text-align: center;
    }

    .flow-description a {
      color: $success;
    }

    .instruction-list {
      border-top: 1px solid $border;
    }

    .instruction-item {
      padding: 12px 0 5px 0;
    }

    .instruction-link {
      color: $success;
      text-decoration: underline;

      &:hover {
        color: $success;
        opacity: 0.8;
        text-decoration: underline;
      }

      &:active {
        color: darken($success, 5);
        opacity: 1;
        text-decoration: underline;
      }
    }

    .instruction-index {
      color: $success;
      background: $success_bg;
      font-size: 13px;
      padding: 5px 0;
      width: 60px;
      display: block;
      text-align: center;
      border-radius: 3px;
      margin-top: 5px;
    }

    .ant-col .section-item:not(:first-child) {
      margin-top: 20px;
    }

    .section-title {
      font-weight: 600;
      font-size: 15px;
    }

    .section-description {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .flow-intro-btn-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .flow-intro-btn {
      border-top: 1px solid $border;
      background: $secondary_bg;
      padding: 0px 20px 20px 20px;
      max-width: 600px;
      margin: -20px auto 0 auto;
    }
  }

  // CARD HOVER EFFECT

  .ant-card {

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
    border: 1px solid $border;
    border-radius: 5px;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly increase shadow
      transform: translateY(-5px); // Move panel up slightly
    }
  }

  .ant-card.flat {
    box-shadow: none;

    &:hover {
      box-shadow: none;
      transform: none;
      cursor: default;
    }
  }

}

// Loader

.preloader {
  align-items: center;
  background: $secondary_bg;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}

.remove-item {
  color: $error !important;
}

